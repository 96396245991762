import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../AuthContext/AuthContext';

function Courses() {
    var rowNum = 1;
    const [coursesData, setCoursesData] = useState([]);
    const { logout, platformData, fetchPlatform } = useContext(AuthContext);

    useEffect(() => {
        try {
            if (platformData.length > 0) {
                var tmpCourses = platformData.map((item) => {
                    var course = {};

                    course.id = item.course;
                    course.fullname = item.courseInfo.fullname;
                    course.sitename = item.courseInfo.branchName;

                    item.courseInfo.customFields.map((item) => {
                        switch (item.shortname) {
                            case "cycle_hours":
                                course.cyclehours = item.value;
                                break;
                            case "type":
                                course.type = item.value;
                                break;
                            case "course_specialty":
                                course.specialty = item.value;
                                break;
                            case "cyclekz":
                                course.cyclekz = item.value;
                                break;
                        }
                    });

                    return course;
                });

                var distinctCourses = getDistinctObjects(tmpCourses);
                setCoursesData(distinctCourses);
            } else {
                const fetchData = async () => {
                    await fetchPlatform();
                }
                fetchData();
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            if (error.response && error.response.status === 401) {
                logout();
            }
        }
    }, [platformData]);

    function getDistinctObjects(objects) {
        const unique = objects.reduce((acc, current) => {
            const x = acc.find(item => item.id === current.id);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);

        return unique;
    }

    return (
        <>
            <div>
                <p className="h3 px-2 pt-3">Курсы</p>
            </div>
            <div className="px-2 pt-3">
                <div className="border rounded px-1">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Название</th>
                                <th scope="col">Тип</th>
                                <th scope="col">Специальность</th>
                                <th scope="col">Часы</th>
                                {/* <th scope="col">Кол-во слушателей</th> */}
                                <th scope="col">Филиал</th>
                                <th scope="col">Управление</th>
                            </tr>
                        </thead>
                        <tbody>
                            {coursesData.map((item) => (
                                <tr key={item.id}>
                                    <th scope="row">{rowNum++}</th>
                                    <td>{item.fullname}</td>
                                    <td>{item.type}</td>
                                    <td>{item.specialty}</td>
                                    <td>{item.cyclehours}</td>
                                    {/* <td>{item.studentsCount}</td> */}
                                    <td>{item.sitename}</td>
                                    <td>
                                        <Link className="ms-2" to={`/details/courses/${item.id}`}>Открыть</Link>
                                        {/* <Link className="ms-2" to={`/edit/courses/${item.id}`}>Edit</Link> */}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default Courses;