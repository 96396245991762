import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import logopng from '../../img/logo.png';
import AuthContext from '../../components/AuthContext/AuthContext';

function Navbar() {
    const { logout, isLoggedIn } = useContext(AuthContext);
    const branchName = localStorage.getItem('branchName');

    return (
        <nav className="navbar navbar-expand-lg navbar-light border-right border-2 border-primary">
            <div className="container-fluid">
                <Link className="navbar-brand" aria-current="page" to="/"><img src={logopng} alt="Выпуск и учет свидетельств РМА"/></Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link className="nav-link" aria-current="page" to="/">Главная</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" aria-current="page" to="/details/courses">Курсы</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" aria-current="page" to="/details/listeners">Слушатели</Link>
                        </li>
                    </ul>
                    <div className='ms-auto' style={{fontSize: "20px"}}>{branchName}</div>
                    
                    <ul className="navbar-nav ms-5">
                        {
                            isLoggedIn && (
                                <li className='nav-item'>
                                    <span className="nav-link text-primary text-decoration-underline" onClick={logout}>Выйти</span>
                                </li>
                            )
                        }
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;