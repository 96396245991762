import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/ru';
import './CertModal.css';

function CertModal({ show, data, onClose, issuingStatus, setIssuingStatus }) {
    const [docNum, setDocNum] = useState("");
    const [fullName, setFullName] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [type, setType] = useState("");
    const [typeKZ, setTypeKZ] = useState("");
    const [specialty, setSpecialty] = useState("");
    const [specialtyKZ, setSpecialtyKZ] = useState("");
    const [cycle, setCycle] = useState("");
    const [cycleKZ, setCycleKZ] = useState("");
    const [hours, setHours] = useState("");
    const [credits, setCredits] = useState("");
    const [issueDate, setIssueDate] = useState("");
    const [studentId, setStudentId] = useState("");
    const [courseId, setCourseId] = useState("");
    const [transcriptFields, setTranscriptFields] = useState([]);

    const [showTranscript, setShowTranscript] = useState(false);

    useEffect(() => {
        async function fetchData() {
            try {
                var xdata = data;

                var prefix = localStorage.getItem('prefix');
                var lastId = await getLastId();
                if (lastId == 0) {
                    lastId = 1;
                } else {
                    lastId++;
                }

                if (xdata) {
                    var typeKZ = formatStrToKaz(xdata.type);
                    var specialtyKZ = formatStrToKaz(xdata.specialty);
                    if (xdata.enrol_timestart) {
                        var t = new Date(xdata.enrol_timestart * 1000);
                        setFromDate(() => moment(t).format('DD-MM-YYYY'));
                    } else {
                        setFromDate("");
                    }
                    if (xdata.enrol_timeend) {
                        var c = new Date(xdata.enrol_timeend * 1000);
                        setToDate(() => moment(c).format('DD-MM-YYYY'));
                    } else {
                        setToDate("");
                    }
                    setDocNum(prefix + "-" + lastId);
                    setFullName(xdata.lastname + " " + xdata.firstname + " " + xdata.middlename);
                    setType(xdata.type);
                    setTypeKZ(typeKZ);
                    setSpecialty(xdata.specialty);
                    setSpecialtyKZ(specialtyKZ);
                    setCycle(xdata.fullname);
                    setCycleKZ(xdata.cyclekz);
                    setStudentId(xdata.userid);
                    setCourseId(xdata.course);
                    setHours(xdata.cyclehours);
                    setCredits(xdata.credits);
                    var n = new Date(xdata.enrol_timeend * 1000); // по просьбе филиалов, в группе whatsapp обсуждалось, возражений не было.
                    setIssueDate(() => moment(n).format('DD-MM-YYYY'));

                    if (xdata.type == 'Сертификационный курс') {
                        setShowTranscript(true);
                    }
                }
            } catch (error) {
                alert(error);
            }
        }

        fetchData();
    }, [data]);

    const loadTranscriptThemes = async () => {
        if (showTranscript && specialty && specialtyKZ) {
            try {
                const jwttoken = localStorage.getItem("token");
                if (!jwttoken) {
                    console.error("JWT TOKEN IS NOT AVAILABLE");
                    return;
                }
                const headers = {
                    'Authorization': `Bearer ${jwttoken}`,
                    'Content-Type': 'application/json'
                }
                const payload = {
                    specialtyRU: cycle,
                    specialtyKZ: cycleKZ
                }
                const response = await axios.post('https://rmacerts.ripov-edu.kz/api/getTranscriptThemes', payload, { headers });

                if (response.status == 200) {
                    var data = response.data;
                    var newFields = [];

                    for (var i = 0; i < data.length; i++) {
                        var eachData = data[i];

                        var themesArrRu = eachData.ThemeNameRU.split(';');
                        var themesArrKz = eachData.ThemeNameKZ.split(';');

                        if (themesArrRu.length == themesArrKz.length) {
                            for (var j = 0; j < themesArrKz.length; j++) {
                                const field = {
                                    name: themesArrRu[j],
                                    credit: "",
                                    grade: "",
                                    nameKZ: themesArrKz[j],
                                    creditKZ: "",
                                    gradeKZ: ""
                                };

                                newFields.push(field);

                            }
                        }
                    }
                    setTranscriptFields(transcriptFields => [...transcriptFields, ...newFields]);

                }
            }
            catch (err) {
                console.log(err);
            }

        }
    }
    useEffect(() => {
        if (showTranscript == true) {
            loadTranscriptThemes();
        }
    }, [showTranscript]);


    if (!show) return null;
    const modalClass = show ? "modal fade show d-block" : "modal fade";

    const handleIssueCertificate = async (event) => {
        event.preventDefault();

        if (!docNum || !fullName || !fromDate || !toDate || !type || !typeKZ || !specialty || !specialtyKZ || !cycle || !cycleKZ || !hours || !issueDate) {
            alert("Все поля должны быть заполнены. Проверьте настройки слушателя в платформе.");
            return;
        }

        moment.locale('ru');
        var formattedFromDate = formatDate(fromDate, 0);
        var formattedToDate = formatDate(toDate, 1);
        var formattedIssueDate = formatDate(issueDate, 2);

        const payload = {
            docNum: docNum ? docNum : '',
            FullName: fullName ? fullName : '',
            fromDate: moment(fromDate, "DD-MM-YYYY").format('DD MMMM YYYY года').replace(/^(\d+)/, '"$1"'),
            fromDateOrig: fromDate,
            fromDateKZ: formattedFromDate ? formattedFromDate : '',
            toDate: moment(toDate, "DD-MM-YYYY").format('DD MMMM YYYY года').replace(/^(\d+)/, '"$1"'),
            toDateOrig: toDate,
            toDateKZ: formattedToDate ? formattedToDate : '',
            certType: type ? type.trim() : '',
            certTypeKZ: typeKZ ? typeKZ.trim() : '',
            Specialty: specialty ? specialty.trim() : '',
            SpecialtyKZ: specialtyKZ ? specialtyKZ.trim() : '',
            Cycle: cycle ? cycle.trim() : '',
            CycleKZ: cycleKZ ? cycleKZ.trim() : '',
            hours: hours ? hours : '',
            credits: credits,
            issueDate: moment(issueDate, "DD-MM-YYYY").format('DD MMMM YYYY года').replace(/^(\d+)/, '"$1"'),
            issueDateOrig: issueDate,
            issueDateKZ: formattedIssueDate ? formattedIssueDate : '',
            StudentId: studentId,
            CourseId: courseId,
            BranchId: localStorage.getItem("branchId")
        };

        if (showTranscript) {
            payload.IsTranscript = showTranscript;
            payload.themes = transcriptFields;
        }

        try {
            const jwttoken = localStorage.getItem("token");
            if (!jwttoken) {
                console.error("JWT TOKEN IS NOT AVAILABLE");
                return;
            }
            const headers = {
                'Authorization': `Bearer ${jwttoken}`,
                'Content-Type': 'application/json'
            }
            setIssuingStatus(true);
            const response = await axios.post('https://rmacerts.ripov-edu.kz/api/generate-pdf', payload, { headers });
            setIssuingStatus(false);
            console.log(response.data);
            setTranscriptFields([]);
            onClose(setShowTranscript);
        } catch (error) {
            setIssuingStatus(false);
            console.error(error);
            if (error && error.response && error.response.data) {
                if (error.response.data.error) {
                    alert(error.response.data.error);
                } else {
                    alert(error.response.data);
                }
            } else {
                alert("Попробуйте перезайти.");
            }
        }
    };

    const formatDate = (dateStr, suffixFormat) => {
        const monthsWithSuffix = [
            'қаңтар', 'ақпан', 'наурыз', 'сәуір', 'мамыр', 'маусым',
            'шілде', 'тамыз', 'қыркүйек', 'қазан', 'қараша', 'желтоқсан'
        ];
        const monthsWithSuffixKZ = [
            'қаңтардан', 'ақпандан', 'наурыздан', 'сәуірден', 'мамырдан', 'маусымнан',
            'шілдеден', 'тамыздан', 'қыркүйектен', 'қазаннан', 'қарашадан', 'желтоқсаннан'
        ];
        const monthsToKZ = [
            'қаңтарға', 'ақпанға', 'наурызға', 'сәуірге', 'мамырға', 'маусымға',
            'шілдеге', 'тамызға', 'қыркүйекке', 'қазанға', 'қарашаға', 'желтоқсанға'
        ];

        const date = moment(dateStr, "DD-MM-YYYY");
        const day = date.format("DD");
        const monthIndex = date.month();
        const year = date.format("YYYY");
        const monthWithSuffix = monthsWithSuffix[monthIndex];
        const monthWithFromSuffix = monthsWithSuffixKZ[monthIndex];
        const monthWithToSuffix = monthsToKZ[monthIndex];
        if (suffixFormat == 0) {
            return `${year} жылғы "${day}" ${monthWithFromSuffix}`;
        } else if (suffixFormat == 1) {
            return `${year} жылғы "${day}" ${monthWithToSuffix}`;
        } else {
            return `${year} жылғы "${day}" ${monthWithSuffix}`;
        }
    };
    function formatStrToKaz(str) {
        var strKZ = "";
        switch (str) {
            case "Акушерское дело":
                strKZ = "Акушериялық іс";
                break;
            case "Лечебное дело":
                strKZ = "Емдеу ісі";
                break;
            case "Лабораторная диагностика":
                strKZ = "Зертханалық диагностика";
                break;
            case "Медицинская оптика":
                strKZ = "Медициналық оптика";
                break;
            case "Сестринское дело":
                strKZ = "Мейіргер ісі";
                break;
            case "Сестринское дело (массажист)":
                strKZ = "Мейіргіер ісі (массажист)";
                break;
            case "Стоматология":
                strKZ = "Стоматология";
                break;
            case "Стоматология ортопедическая":
                strKZ = "Стоматологиялық ортопедия";
                break;
            case "Гигиена и эпидемиология":
                strKZ = "Гигиена және эпидемиология";
                break;
            case "Повышение квалификации":
                strKZ = "Біліктілікті арттыру";
                break;
            case "Фармация":
                strKZ = "Фармация";
                break;
            case "Сертификационный курс":
                strKZ = "Сертификаттау курсы";
                break;
            case "Инструктор-дезинфектор":
                strKZ = "Нұсқаушы-дезинфектор";
                break;
            case "Инструктор по лечебной физкультуре":
                strKZ = "Емдік денешынықтыру нұсқаушысы";
                break;
            case "Сестринское дело в рентгенологии":
                strKZ = "Рентгенологиядағы мейіргер ісі";
                break;
            default:
                strKZ = str;
                break;
        }

        return strKZ;
    }

    const addTranscriptField = (event) => {
        event.preventDefault();

        const newField = {
            name: "",
            credit: "",
            grade: "",
            nameKZ: "",
            creditKZ: "",
            gradeKZ: ""
        };
        setTranscriptFields([...transcriptFields, newField]);
    };

    const handleTranscriptChange = (index, field, value) => {
        const updatedFields = transcriptFields.map((item, i) => {
            if (i === index) {
                return { ...item, [field]: value };
            }
            return item;
        });
        setTranscriptFields(updatedFields);
    };

    const closeModal = () => {
        setTranscriptFields([]);
        onClose(setShowTranscript);
    };

    async function getLastId() {
        try {
            if (localStorage.getItem('role') === 'admin') {
                return 0;
            }
            const jwttoken = localStorage.getItem("token");
            const prefix = localStorage.getItem("prefix");

            if (!jwttoken || !prefix) {
                console.error();
                throw new Error("JWT TOKEN OR PREFIX IS NOT AVAILABLE");
            }
            const headers = {
                'Authorization': `Bearer ${jwttoken}`,
                'Content-Type': 'application/json'
            }
            var payload = {
                prefix: prefix
            }
            const response = await axios.post('https://rmacerts.ripov-edu.kz/api/getLastId', payload, { headers });
            if (response.status >= 200 & response.status < 300) {
                return response.data;
            } else {
                alert("Ошибка получения номера последнего документа, обратитесь к администратору.");
                return 0;
            }
        } catch (error) {
            alert("Выпуск сертификата" + error);
            throw new Error(error);
        }
    }

    return (
        <div className={modalClass}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{data.fullname}</h5>
                    </div>
                    <form onSubmit={handleIssueCertificate}>
                        <div className="modal-body">
                            <label htmlFor="docNum" className="form-label">Номер документа:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="docNum"
                                value={docNum || ''}
                                onChange={(e) => setDocNum(e.target.value)}
                                required
                                disabled
                            /><br />
                            <label htmlFor="FullName" className="form-label">ФИО:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="FullName"
                                value={fullName || ''}
                                // onChange={(e) => setFullName(e.target.value)}
                                required
                                disabled
                            /><br />
                            <label htmlFor="fromDate" className="form-label">От даты:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="fromDate"
                                value={fromDate || ''}
                                onChange={(e) => setFromDate(e.target.value)}
                                required
                                disabled
                            /><br />
                            <label htmlFor="toDate" className="form-label">По дате:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="toDate"
                                value={toDate || ''}
                                onChange={(e) => setToDate(e.target.value)}
                                required
                                disabled
                            /><br />
                            <label htmlFor="type" className="form-label">Тип:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="type"
                                value={type || ''}
                                // onChange={(e) => setType(e.target.value)}
                                required
                                disabled
                            /><br />
                            <label htmlFor="typeKZ" className="form-label">Тип (каз):</label>
                            <input
                                type="text"
                                className="form-control"
                                id="typeKZ"
                                value={typeKZ || ''}
                                // onChange={(e) => setTypeKZ(e.target.value)}
                                required
                                disabled
                            /><br />
                            <label htmlFor="specialty" className="form-label">Специальность:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="specialty"
                                value={specialty || ''}
                                //onChange={(e) => setSpecialty(e.target.value)}
                                required
                                disabled
                            /><br />
                            <label htmlFor="specialtyKZ" className="form-label">Специальность (каз):</label>
                            <input
                                type="text"
                                className="form-control"
                                id="specialtyKZ"
                                value={specialtyKZ || ''}
                                // onChange={(e) => setSpecialtyKZ(e.target.value)}
                                required
                                disabled
                            /><br />
                            <label htmlFor="cycle" className="form-label">Цикл:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="cycle"
                                value={cycle || ''}
                                // onChange={(e) => setCycle(e.target.value)}
                                required
                                disabled
                            /><br />
                            <label htmlFor="cycleKZ" className="form-label">Цикл (каз):</label>
                            <input
                                type="text"
                                className="form-control"
                                id="cycleKZ"
                                value={cycleKZ || ''}
                                // onChange={(e) => setCycleKZ(e.target.value)}
                                required
                                disabled
                            /><br />
                            <label htmlFor="hours" className="form-label">Часы:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="hours"
                                value={hours || ''}
                                // onChange={(e) => setHours(e.target.value)}
                                required
                                disabled
                            /><br />
                            <label htmlFor="credits" className="form-label">Кредиты:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="credits"
                                value={credits || ''}
                                // onChange={(e) => setHours(e.target.value)}
                                required
                                disabled
                            /><br />
                            <label htmlFor="issueDate" className="form-label">Дата выдачи:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="issueDate"
                                value={issueDate || ''}
                                onChange={(e) => setIssueDate(e.target.value)}
                                required
                            />
                            <div className='transcriptBlock'>
                                {
                                    showTranscript && (
                                        <div>
                                            <div className='text-center py-3'>
                                                <h4>Транскрипт</h4>
                                            </div>
                                            {transcriptFields.map((field, index) => (
                                                <div key={index} className={`border mt-2 p-4 ${index % 2 === 0 ? 'custom-bg-secondary' : 'custom-bg-primary'}`}>
                                                    <div className='py-3'>
                                                        <h5 className='text-success'>Тема {index + 1}</h5>
                                                    </div>
                                                    <label htmlFor="tName" className="form-label">Наименование:</label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="tName"
                                                        value={field.name}
                                                        onChange={(e) => handleTranscriptChange(index, 'name', e.target.value)}
                                                    />
                                                    <div className='d-flex pt-2 pb-3'>
                                                        <div className='w-50 px-1'>
                                                            <label htmlFor="tName" className="form-label">Кредиты:</label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                id="tCredit"
                                                                value={field.credit}
                                                                onChange={(e) => handleTranscriptChange(index, 'credit', e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                        <div className='w-50 px-1'>
                                                            <label htmlFor="tName" className="form-label">Итоговая оценка:</label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                id="tGrade"
                                                                value={field.grade}
                                                                onChange={(e) => handleTranscriptChange(index, 'grade', e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <label htmlFor="tNameKZ" className="form-label">Атауы:</label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="tNameKZ"
                                                        value={field.nameKZ}
                                                        onChange={(e) => handleTranscriptChange(index, 'nameKZ', e.target.value)}
                                                    />
                                                    <div className='d-flex pt-2 pb-3'>
                                                        <div className='w-50 px-1'>
                                                            <label htmlFor="tCreditKZ" className="form-label">Кредиттер:</label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                id="tCreditKZ"
                                                                value={field.creditKZ}
                                                                onChange={(e) => handleTranscriptChange(index, 'creditKZ', e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                        <div className='w-50 px-1'>
                                                            <label htmlFor="tGradeKZ" className="form-label">Қортынды баға:</label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                id="tGradeKZ"
                                                                value={field.gradeKZ}
                                                                onChange={(e) => handleTranscriptChange(index, 'gradeKZ', e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            <button className="mt-3 btn btn-success" onClick={addTranscriptField}>Добавить темы</button>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success" disabled={issuingStatus}>Выпустить</button>
                            <button type="button" className="btn btn-secondary" disabled={issuingStatus} onClick={closeModal}>Закрыть</button>
                        </div>
                    </form>
                </div>
            </div>
        </div >
    );
}

export default CertModal;