import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import AuthContext from '../../components/AuthContext/AuthContext';

function ListenerCerts(props) {
    let { userid, issuingStatus } = props;
    const [listenerCerts, setListenerCerts] = useState([]);
    const { fetchCerts } = useContext(AuthContext);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let tmpArray= [];

                const response = await fetchCerts(userid);
                if (response.length > 0) {
                    setListenerCerts(response);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [userid, issuingStatus]);

    const handleDelete = async (e, id) => {
        e.preventDefault();

        const jwttoken = localStorage.getItem("token");
        if (!jwttoken) {
            console.error("JWT TOKEN IS NOT AVAILABLE");
            return;
        }
        const headers = {
            'Authorization': `Bearer ${jwttoken}`,
            'Content-Type': 'application/json'
        }
        const response = await axios.get(`https://rmacerts.ripov-edu.kz/api/deleteCert/${id}`, { headers });
        if (response.status >= 200 && response.status < 300) {
            alert("Удалено");
            const updResponse = await fetchCerts(userid);
            setListenerCerts(updResponse);
        }
    }

    var rowNum = 1;
    return (
        <div className="px-2 pt-3">
            <div className="border rounded px-1">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Название</th>
                            <th scope="col">Тип</th>
                            <th scope="col">Специальность</th>
                            <th scope="col">Часы</th>
                            <th scope="col">Номер документа</th>
                            <th scope="col">Филиал</th>
                            <th scope="col">Управление</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listenerCerts.map((item) => (
                            <tr key={item.Id}>
                                <th scope="row">{rowNum++}</th>
                                <td>{item.CourseName}</td>
                                <td>{item.TypeName}</td>
                                <td>{item.SpecialtyName}</td>
                                <td>{item.Hours}</td>
                                <td>{item.DocumentNumber}</td>
                                <td>{item.BranchName}</td>
                                <td>
                                    <a className="ms-2" href={`${item.FileLink}`} target="_blank" rel="noopener noreferrer">Посмотреть</a>
                                    {
                                        item.TranscriptLink != null ? (
                                            <a className="ms-2" href={`${item.TranscriptLink}`} target="_blank" rel="noopener noreferrer">Транскрипт</a>
                                        ) : (<span></span>)
                                    }
                                    {
                                        localStorage.getItem('role') == 'admin' ? (
                                            <a className='' href="#" onClick={(e) => handleDelete(e, item.Id)} target="_blank" rel="noopener noreferrer">Удалить</a>
                                        ) : (<span></span>)
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ListenerCerts;