import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import logopng from '../../img/logo.png';
import AuthContext from '../AuthContext/AuthContext';

function Listeners() {
    const { platformData } = useContext(AuthContext);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    var rowNum = 1;

    useEffect(() => {
        const filtered = platformData.filter(item => {
            let fullName = "";
            if (searchTerm.length > 0) {
                fullName = `${item.userInfo.lastname} ${item.userInfo.firstname} ${item.userInfo.middlename}`.toLowerCase();
            }
            
            return fullName.includes(searchTerm.toLowerCase());
        });
        setFilteredData(filtered);
    }, [searchTerm, platformData]);

    return (
        <div className="px-2 pt-3">
            <div className="border rounded px-1">
                <input 
                    type="text" 
                    className="form-control my-3" 
                    placeholder="Поиск по ФИО..." 
                    value={searchTerm} 
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">ФИО</th>
                            <th scope="col">ИИН</th>
                            <th scope="col">Идентификатор платформы</th>
                            <th scope="col">Филиал</th>
                            <th scope="col">Управление</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.map((item) => (
                            <tr key={item.id}>
                                <th scope="row">{rowNum++}</th>
                                <td>{item.userInfo.lastname + ' ' + item.userInfo.firstname + ' ' + item.userInfo.middlename}</td>
                                <td>{item.userInfo.customFields[0].fieldvalue}</td>
                                <td>{item.userid}</td>
                                <td>{item.courseInfo.branchName}</td>
                                <td>
                                    <Link className="ms-2" to={`/details/listeners/${item.userid}`}>Открыть</Link>
                                    {/* <Link className="ms-2" to={`/edit/listeners/${item.userid}`}>Edit</Link> */}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Listeners;