import React, { useEffect, useContext } from 'react';

// components
import Listeners from '../Listeners/Listeners';
import Courses from '../Courses/Courses';
import AuthContext from '../AuthContext/AuthContext';

function Home() {
    const { logout, platformData, fetchPlatform } = useContext(AuthContext);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (platformData.length == 0) {
                    await fetchPlatform();
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                if (error.response && error.response.status === 401) {
                    logout();
                }
            }
        };

        fetchData();
    }, [platformData]);

    return (
        <>
            <div className="block1 mt-3">
                <h3>Слушатели</h3>
                <Listeners listeners={platformData} />
            </div>
            <div className="block2 mt-3">
                <Courses />
            </div>
        </>
    );
}

export default Home;