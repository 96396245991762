import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../components/AuthContext/AuthContext';
import axios from 'axios';

const Login = () => {
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post('https://rmacerts.ripov-edu.kz/api/login', {
        username,
        password
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const data = response.data;
      if (response.status === 200) {
        login(username, data);
        navigate('/');
      } else {
        throw new Error('Login failed');
      }
    } catch (error) {
      console.error('Login error:', error);
      alert('Ошибка авторизации. Попробуйте снова.');
    }
  };

  return (
    <div className="container pt-5" style={{maxWidth: "400px"}}>
      <h2 className="pb-2 text-center text-primary">Авторизация</h2>
      <form onSubmit={handleLogin}>
        <div className='mb-3'>
          <label className='form-label'>Логин:</label>
          <input className='form-control' type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
        </div>
        <div className='mb-3'>
          <label className='form-label'>Пароль:</label>
          <input className='form-control' type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
        </div>
        <button className="btn btn-primary mt-3" type="submit">Войти</button>
      </form>
    </div>
  );
};

export default Login;